import { useEffect, useState } from 'react';

import { wharehouseServiceInstance, planServiceInstance } from '@front/services';
import { Toast, DeliveryForm, DeliveriesList, SuccessPlan } from '@ui/organisms';

import Container from 'react-bootstrap/Container';

import { translate } from '@helpers/i18n';
import { Loader } from '@ui/atoms';
import { DeliveryDTOWithStatus } from '@shared/types';
import { JSONErrorDTO } from '@shared/interfaces';
import styles from './PlanningPage.module.scss';

const PlanningPage = (): JSX.Element => {
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const [isReplanning, setIsReplanning] = useState<boolean>(false);
  const [planId, setPlanId] = useState<string>();
  const [wharehouse, setWharehouse] = useState<string | undefined>(undefined);
  const [date, setDate] = useState<string | undefined>(undefined);
  const [deliveries, setDeliveries] = useState<DeliveryDTOWithStatus[]>([]);
  const [isSuccessPlan, setSuccessPlan] = useState<boolean>(false);

  useEffect(() => {
    const getPlan = async () => {
      if (!wharehouse || !date) {
        Toast({ type: 'error', message: translate('PLANNING_PAGE.ERROR_REQUIRED_PARAMETERS') });
        return;
      }

      setShowSpinner(true);

      try {
        const plan = await planServiceInstance.getPlanByWhCodeAndDate(date, wharehouse);

        if (plan) {
          setPlanId(plan.id);
          setIsReplanning(true);
        } else {
          setIsReplanning(false);
        }
      } catch (err) {
        const error = err as JSONErrorDTO;
        Toast({
          type: 'error',
          message: error.error.description || 'ERROR.UNEXPECTED_ERROR_OCURRED',
        });
      }

      setShowSpinner(false);
    };

    if (wharehouse && date) getPlan();
  }, [wharehouse, date]);

  const submitDeliveryForm = async () => {
    if (!wharehouse || !date) {
      Toast({ type: 'error', message: translate('PLANNING_PAGE.ERROR_REQUIRED_PARAMETERS') });
      return;
    }

    setShowSpinner(true);

    try {
      let d: Array<DeliveryDTOWithStatus> = [];

      if (isReplanning && planId !== undefined) {
        d = await planServiceInstance.replanning(planId, date, wharehouse);
      } else {
        d = await wharehouseServiceInstance.getDeliveries(wharehouse, date);
      }

      setDeliveries(d);

      if (d.length === 0) {
        Toast({ type: 'info', message: translate('PLANNING_PAGE.NO_DELIVERIES') });
      }
    } catch (err) {
      const error = err as JSONErrorDTO;
      Toast({
        type: 'error',
        message: error.error.description || 'ERROR.UNEXPECTED_ERROR_OCURRED',
      });
    }

    setShowSpinner(false);
  };

  const toPlan = async () => {
    if (!wharehouse || !date || deliveries.length === 0) {
      Toast({ type: 'error', message: translate('PLANNING_PAGE.ERROR_REQUIRED_PARAMETERS') });
      return;
    }

    setShowSpinner(true);

    try {
      if (isReplanning && planId !== undefined) {
        await planServiceInstance.updatePlan(planId, date, wharehouse, deliveries);
      } else {
        await planServiceInstance.createPlan(date, wharehouse, deliveries);
      }
      setSuccessPlan(true);
    } catch (err) {
      const error = err as JSONErrorDTO;
      Toast({
        type: 'error',
        message: error.error.description || 'ERROR.UNEXPECTED_ERROR_OCURRED',
      });
    }

    setShowSpinner(false);
  };

  const resetPlanification = () => {
    setSuccessPlan(false);
    setDeliveries([]);
    setPlanId(undefined);
  };

  return (
    <Container className={styles.PlanningPage}>
      {showSpinner && <Loader />}
      {!isSuccessPlan && deliveries.length === 0 && (
        <DeliveryForm
          onSubmit={submitDeliveryForm}
          setWharehouse={setWharehouse}
          setDate={setDate}
          isReplanning={isReplanning}
        />
      )}

      {!isSuccessPlan && deliveries.length > 0 && date && wharehouse && (
        <DeliveriesList
          date={date}
          whCode={wharehouse}
          deliveries={deliveries}
          onSubmit={toPlan}
          isReplanning={isReplanning}
        />
      )}
      {isSuccessPlan && date && wharehouse && (
        <SuccessPlan
          date={date}
          whCode={wharehouse}
          onFinish={resetPlanification}
          isReplanning={isReplanning}
        />
      )}
    </Container>
  );
};

export default PlanningPage;
