import { useState, FormEvent } from 'react';
import { useRouter } from 'next/router';
import { Button, Col, Row, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-regular-svg-icons';

import { translate } from '@helpers/i18n';
import { authServiceInstance } from '@front/services';
import { Logo } from '@ui/atoms';
import { Toast } from '@ui/organisms';

import configuration from '@config/index';
import { JSONErrorDTO } from '@shared/interfaces';
import styles from './LoginPage.module.scss';

const LoginPage = (): JSX.Element => {
  const router = useRouter();
  const [password, setPassword] = useState<string>(configuration.development ? '123qweQWE' : '');
  const [user, setUser] = useState<string>(configuration.development ? 'dev@nakima.es' : '');
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const submit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!user || !password) {
      return;
    }
    try {
      await authServiceInstance.login(user, password);
      router.push('/plan');
    } catch (err) {
      const error = err as JSONErrorDTO;

      if (error.code === 401) {
        Toast({ type: 'error', message: translate('ERROR.USER_PASSWORD_INVALID') });
      } else {
        Toast({
          type: 'error',
          message: error.error.description || 'ERROR.UNEXPECTED_ERROR_OCURRED',
        });
      }
    }
  };

  return (
    <div className={styles.LoginPage}>
      <Row className="mb-5">
        <Logo />
      </Row>
      <Col xs={12} sm={9} md={7} lg={5} className={`${styles.Card} card`}>
        <h4 className="text-align-center mb-3">{translate('LOGIN.LOGIN')}</h4>
        <Form onSubmit={submit}>
          <Form.Group className="mb-3">
            <Form.Label>{translate('LOGIN.USER')}</Form.Label>
            <Form.Control
              defaultValue={user}
              type="text"
              placeholder="Intrudicir usuario"
              onChange={({ target }) => setUser(target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>{translate('LOGIN.PASSWORD')}</Form.Label>
            <div className={styles['input-password']}>
              <Form.Control
                defaultValue={password}
                onChange={({ target }) => setPassword(target.value)}
                type={showPassword ? 'text' : 'password'}
                placeholder="Introducir contraseña"
                autoComplete="off"
              />
              <span className={styles.icon}>
                <FontAwesomeIcon icon={faEye} onClick={() => setShowPassword(!showPassword)} />
              </span>
            </div>
          </Form.Group>
          <p className="mb-2">{translate('LOGIN.RECOVERY_PASSWORD')}</p>
          <Button type="submit" className="w-100">
            {translate('LOGIN.CONTINUE')}
          </Button>
        </Form>
      </Col>
    </div>
  );
};

export default LoginPage;
